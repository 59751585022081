<template>
      <div class="">
      <div class="d-flex mb-2">
          <span class="page-header-title" v-if="managerId">Service Zonal Edit</span>
          <span class="page-header-title" v-else>Service Zonal Registration</span>
    </div>
  <div class="row">
    <div class="col-md-12 manager-input">
      <CCard>
        <CCardBody>
          <CForm novalidate @submit.prevent="submitForm">
            <div role="group" class="form-group d-flex mb-0">
              <label class="asterisk col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Name
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <CInput v-model="ManagerRegistrationForm.name" required was-validated placeholder="Name" />
                <show-errors :control="$v.ManagerRegistrationForm.name" :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="asterisk col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Email ID
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <CInput v-model.trim="ManagerRegistrationForm.email" v-if="managerId" was-validated placeholder="Email ID" disabled />
                <CInput v-model.trim="ManagerRegistrationForm.email" v-else required was-validated placeholder="Email ID"  />
                <show-errors :control="$v.ManagerRegistrationForm.email" :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Country Code
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <b-form-select v-model="ManagerRegistrationForm.country_code" :options="countryCodeList">
                </b-form-select>
                <show-errors :control="$v.ManagerRegistrationForm.country_code"
                  :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Phone Number
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <CInput v-model="ManagerRegistrationForm.phone" @keypress="onlyNumberWithOutDot" v-on:keypress.e.prevent
                  @keydown.space.prevent placeholder="Phone Number" @blur="$v.ManagerRegistrationForm.phone.$touch()" />
                <show-errors :control="$v.ManagerRegistrationForm.phone" :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Company Name
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <CInput v-model="ManagerRegistrationForm.company_name" placeholder="Company Name" />
                <show-errors :control="$v.ManagerRegistrationForm.company_name"
                  :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Company Address
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <CInput v-model="ManagerRegistrationForm.company_address" placeholder="Company Address" />
                <show-errors :control="$v.ManagerRegistrationForm.company_address"
                  :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Designation
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <CInput v-model="ManagerRegistrationForm.designation" placeholder="Designation" />
                <show-errors :control="$v.ManagerRegistrationForm.designation"
                  :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="asterisk col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Country
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">

                <b-form-select v-model="ManagerRegistrationForm.country" :options="optionCountries" class=""
                  @change="selectCountry(ManagerRegistrationForm.country)"></b-form-select>
                <show-errors :control="$v.ManagerRegistrationForm.country" :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="asterisk col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                State
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <b-form-select v-model="ManagerRegistrationForm.state" :options="stateList"
                  @change="selectRegions(ManagerRegistrationForm.state)" class=""></b-form-select>
                <show-errors :control="$v.ManagerRegistrationForm.state" :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                City
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <CInput v-model="ManagerRegistrationForm.city" placeholder="City" />
                <show-errors :control="$v.ManagerRegistrationForm.city" :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Pin Code
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">
                <CInput v-model="ManagerRegistrationForm.pin" placeholder="Pin Code" />
                <show-errors :control="$v.ManagerRegistrationForm.pin" :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div role="group" class="form-group d-flex mb-0 mt-3">
              <label class="asterisk col-xl-2 col-lg-3 col-md-4 labe-class-for-input">
                Region
              </label>
              <div class="col-xl-10 col-lg-9 col-md-8">

                <CInput v-model="ManagerRegistrationForm.region_id" placeholder="Region" disabled />
                <show-errors :control="$v.ManagerRegistrationForm.region_id"
                  :field="$t('app.accountRegistation.name')" />
              </div>
            </div>

            <div class="form-group form-actions float-right mt-3 mr-3 pr-0">
              <b-button size="md" variant="secondary" class="px-2" @click="onCancel">Cancel
              </b-button>
              <b-button size="md" variant="primary" class="ml-3 px-2" type="submit">Save
              </b-button>
            </div>
          </CForm>
        </CCardBody>
      </CCard>
    </div>
  </div>
      </div>
</template>

<script>
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import _ from "lodash";
import {
  pinCodeValidation,
  phoneNoValidation,
  multipleErrorMessages,
  emailValidation,
  onlyNumberWithOutDot,
  senitize,
  allowAddress
} from "@/shared/utils";
import constants from "@/constants/constants.js";
import {
  MANAGER_DETAILS,
  MANAGER_EDIT,
  MANAGER_ADD,
  MANAGER_REGION_LIST,
  SERVICE_ZONAL_REGIONS,
} from "@/store/actions.type";

export default {
  name: "NewAccount",
  components: { ShowErrors },
  data() {
    return {
      optionCountries: constants.optionCountries,
      stateOptionsList: [],
      countryCodeList: constants.countryCodeList,
      optionRegionManagers: [{ value: "", text: "Select Region Name" }],
      isEdit: false,
      managerId: "",
      onlyNumberWithOutDot: onlyNumberWithOutDot,
      selected: [], // Must be an array reference!
      show: true,
      horizontal: {
        label: "col-xl-2 col-lg-3 col-md-4",
        input: "col-xl-10 col-lg-9 col-md-8",
      },
      prefectureList: [],
      ManagerRegistrationForm: {
        name: "",
        email: "",
        phone: null,
        company_name: "",
        company_address: "",
        country: "",
        state: "",
        city: "",
        pin: "",
        region_id: "",
        profile_photo: "",
        designation: "",
        country_code: "",
      },
      regionsStateFilter: {
        start: 0,
        limit: 50,
      },
      stateList: [{ value: "", text: "Select State" }],
      regionName: "",
      countryIdPayload: "",
      stateIdPayload: "",
    };
  },
  validations: {
     ManagerRegistrationForm: {
      name: { required, maxLength: maxLength(30), senitize },
      email: { required, emailValidation },
      phone: { phoneNoValidation },
      company_name: { senitize },
      company_address: { allowAddress },
      country: { required },
      state: { required },
      city: { senitize },
      pin: { pinCodeValidation },
      region_id: { required },
      profile_photo: {},
      designation: {senitize},
      country_code: {
        required: requiredIf(function() {
          return this.ManagerRegistrationForm.phone;
        })
      }
    }
  },
  methods: {
    /**
     * @method selectRegions
     * @description on select of state it list all the regions
     * @author @Jeevan Poojary
     */
    async selectRegions(state) {
      if(this.ManagerRegistrationForm.state != ""){
        this.regionName = "";
      this.ManagerRegistrationForm.region_id = "";
      this.isLoadingState = this.$loading.show();
      let data = await this.$store.dispatch(SERVICE_ZONAL_REGIONS, {
        filters: this.regionsStateFilter,
        country: this.countryIdPayload,
        search: state,
      });
      this.isLoadingState.hide();
      if (_.get(data, "success", true)) {
        let stateDataList = [];
        stateDataList = _.get(data.data, "model.list", []);
        if (stateDataList.length > 0) {
          this.regionName = stateDataList[0].region_id;
          this.ManagerRegistrationForm.region_id = stateDataList[0].region_name;
        }
      } else {
        let message = _.get(data, "message", "");
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
      }else{
         this.ManagerRegistrationForm.region_id = "";
      }
    },

    /**
     * @method selectCountry
     * @description on select of country it list all the state
     * @author @Jeevan Poojary
     */
    async selectCountry(id) {
     if(this.ManagerRegistrationForm.country != ""){
  this.countryIdPayload = id;

      this.stateList = [{ value: "", text: "Select State" }];

      let stateCount = this.stateList.length;
      this.regionName = "";
      this.ManagerRegistrationForm.region_id = "";
      this.isLoadingCountry = this.$loading.show();
      let data = await this.$store.dispatch(SERVICE_ZONAL_REGIONS, {
        filters: this.regionsStateFilter,
        country: this.countryIdPayload,
      });
      this.isLoadingCountry.hide();
      if (_.get(data, "success", true)) {
        let stateDataList = [];
        stateDataList = _.get(data.data, "model.list", []);

        let stateItems = [];
        let i;
        for (i = 0; i < stateDataList.length; i++) {
          stateItems.push({
            text: stateDataList[i].state_name,
            value: stateDataList[i].state_name,
          });
        }
        this.stateList = _.concat(...this.stateList, stateItems);

        if (this.stateList.length != stateCount) {
          this.ManagerRegistrationForm.state = '';
        }

      } else {
        let message = _.get(data, "message", "");
        this.stateList = [];
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
     } else{
       this.stateList = [{ value: "", text: "Select State" }];
        this.ManagerRegistrationForm.state = "";
        this.ManagerRegistrationForm.region_id = "";
     }
    
    },

    /**
     * @method onCancel
     * @description Cancel all the submit data on click of cancel button
     * @author @Jeevan Poojary
     */
    onCancel() {
      setTimeout(() => {
        this.$router.push({
          name: "managerlist",
        });
      }, 0);
    },

    /**
     * @method getManagerData
     * @description API call for getting saved manager data
     * Calling this method on click of particular manager in table list
     * @params : id
     * @payload : id
     * @author @jeevan-agent
     */
    async getManagerData(id) {
      if (this.managerId) {
        this.isLoadingGetData = this.$loading.show();
        await this.$store
          .dispatch(MANAGER_DETAILS, {
            id: this.managerId,
          })
          .then((data) => {
            if (data.success) {
              let detailsDataObj = _.get(data, "managerData", [])

              this.ManagerRegistrationForm.name = detailsDataObj.name != null ? detailsDataObj.name : "";
              this.ManagerRegistrationForm.email =
                detailsDataObj.email != null ? detailsDataObj.email : "";
              let userDetails = detailsDataObj.user_profile;
              this.ManagerRegistrationForm.company_name =
                userDetails.company_name != null ? userDetails.company_name : "";
              this.ManagerRegistrationForm.company_address =
                userDetails.company_address != null
                  ? userDetails.company_address
                  : "";
              this.ManagerRegistrationForm.designation =
                userDetails.designation != null ? userDetails.designation : "";
              this.ManagerRegistrationForm.country =
                userDetails.country != null ? userDetails.country : "";
              this.ManagerRegistrationForm.country_code =
                userDetails.country_code != null ? userDetails.country_code : "";
              this.ManagerRegistrationForm.state =
                userDetails.state != null ? userDetails.state : "";
              this.ManagerRegistrationForm.city =
                userDetails.city != null ? userDetails.city : "";
              this.ManagerRegistrationForm.pin =
                userDetails.postal_code != null ? userDetails.postal_code : "";
              this.ManagerRegistrationForm.phone =
                userDetails.phone_no != null ? userDetails.phone_no : "";

              this.countryIdPayload = this.ManagerRegistrationForm.country;
              this.getAllState(this.ManagerRegistrationForm.country);
              this.getAllRegionsData(this.ManagerRegistrationForm.state)
              this.isLoadingGetData.hide();
            } else {
              this.$toast.removeAll();
              this.$toast.error("ID does not exist. ");
              this.isLoadingGetData.hide();
              this.$router.push({
                name: "managerlist",
              });
            }
            this.isLoadingGetData.hide();
          });
      }

    },

    async getAllState(id) {
      let data = await this.$store.dispatch(SERVICE_ZONAL_REGIONS, {
        filters: this.regionsStateFilter,
        country: id,
      });
      if (_.get(data, "success", true)) {
        let stateDataList = [];
        stateDataList = _.get(data.data, "model.list", []);

        let stateItems = [];
        let i;
        for (i = 0; i < stateDataList.length; i++) {
          stateItems.push({
            text: stateDataList[i].state_name,
            value: stateDataList[i].state_name,
          });
        }
        this.stateList = _.concat(...this.stateList, stateItems);

      }
    },

    async getAllRegionsData(state) {
      let data = await this.$store.dispatch(SERVICE_ZONAL_REGIONS, {
        filters: this.regionsStateFilter,
        country: this.countryIdPayload,
        search: state,
      });

      if (_.get(data, "success", true)) {
        let stateDataList = [];
        stateDataList = _.get(data.data, "model.list", []);
        if (stateDataList.length > 0) {
          this.regionName = stateDataList[0].region_id;
          this.ManagerRegistrationForm.region_id = stateDataList[0].region_name;
        }
      } else {
        let message = _.get(data, "message", "");
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * @method submitForm
     * @description To submit all the account related data on click of save button
     * @author @Jeevan Poojary
     */
    async submitForm() {
      this.$v.ManagerRegistrationForm.$touch();
      if (!this.$v.ManagerRegistrationForm.$invalid) {
        if (!this.managerId) {
          let payload = { ...this.ManagerRegistrationForm };
          payload.region_id = this.regionName;

          this.isLoadingManagerSubmit = this.$loading.show();
          await this.$store.dispatch(MANAGER_ADD, payload).then((data) => {
            if (_.get(data, "data.success", false)) {
              this.isLoadingManagerSubmit.hide();
              this.$toast.removeAll();
              this.$toast.success({
                message: _.get(data, "data.message", ""),
              });
              this.$router.push({
                name: "managerlist",
              });
            } else {
              this.isLoadingManagerSubmit.hide();
              let message = _.get(data, "message", "");
              if (message.length > 0) {
                this.$toast.error({
                  message: message,
                });
              } else {
                this.$toast.removeAll();
                let messageObj = _.get(data, "message", "");
                multipleErrorMessages(this.$toast, messageObj);
              }
            }
          });
        } else {
          let payload = { ...this.ManagerRegistrationForm };
          payload.id = this.managerId;
          payload.region_id = this.regionName;
          this.isLoadingManagerSubmit = this.$loading.show();
          await this.$store.dispatch(MANAGER_EDIT, payload).then((data) => {
            if (_.get(data, "data.success", false)) {
              this.isLoadingManagerSubmit.hide();
              this.$toast.removeAll();
              this.$toast.success({
                message: _.get(data, "data.message", ""),
              });
              this.$router.push({
                name: "managerlist",
              });
            } else {
              this.isLoadingManagerSubmit.hide();
              let message = _.get(data, "message", "");
              if (message.length > 0) {
                this.$toast.error({
                  message: message,
                });
              } else {
                this.$toast.removeAll();
                let messageObj = _.get(data, "message", "");
                multipleErrorMessages(this.$toast, messageObj);
              }
            }
          });
        }
      } else {
        return false;
      }
    },
  },

  watch: {
    $route() {
      if (this.$route.name === "manageredit") {
        this.getManagerData(this.$store.state.selectedManagers || 0);
        this.isEdit = true;
      } else if (this.$route.name === "manageradd") {
        setTimeout(() => {
          this.$v.ManagerRegistrationForm.$reset();
        }, 0);
      }
    },
  },

  /**Mounted method to for on type of url to open popup or prefill the data*/
  mounted() {
    if (this.$route.name === "manageredit") {
      this.getManagerData(this.$store.state.selectedManagers || 0);
      this.isEdit = true;
    } else if (this.$route.name === "manageradd") {
      this.managerId = 0;
      setTimeout(() => {
        this.$v.ManagerRegistrationForm.$reset();
      }, 0);
    }
  },

  async created() {
    this.managerId = this.$store.state.selectedManagers || 0;
    let dropdownRole = await this.$store.dispatch(MANAGER_REGION_LIST);
    let roleDataOptions = [];
    let i;
    for (i = 0; i < dropdownRole.length; i++) {
      roleDataOptions.push({
        text: dropdownRole[i].name,
        value: dropdownRole[i].id,
      });
    }
    this.optionRegionManagers = _.concat(
      ...this.optionRegionManagers,
      roleDataOptions
    );
  },
};
</script>
<style>
.manager-input .form-group {
  margin-bottom: 0rem !important;
}
</style>
